@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);

  app-context-menu {
    mat-toolbar {
      background: mat.get-color-from-palette($background, background) !important;
    }
  }

  .context-menu-container {
    button {
      &:disabled,
      &[disabled] {
        color: mat.get-color-from-palette($foreground, disabled);
        cursor: auto;
      }
    }
  }

  app-main-context-menu {
    mat-toolbar {
      background: mat.get-color-from-palette($background, background) !important;
    }
  }

  .highlighted-button {
    color: mat.get-color-from-palette($primary, default-contrast) !important;
    background-color: mat.get-color-from-palette($primary, default);

    &:disabled,
    &[disabled] {
      background-color: mat.get-color-from-palette($foreground, disabled);
    }

    .light-theme,
    .mat-menu-item,
    .mat-icon-no-color,
    mat-icon {
      color: mat.get-color-from-palette($primary, default-contrast) !important;
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
