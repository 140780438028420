@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);

  .hyperlink-column {
    a {
      color: mat.get-color-from-palette($primary, 300);
    }

    a:hover {
      color: mat.get-color-from-palette($primary, 400);
    }

    a:active {
      color: mat.get-color-from-palette($primary, 500);
    }
  }

  .lookup-text {
    color: mat.get-color-from-palette($primary, 300);

    span:hover {
      color: mat.get-color-from-palette($primary, 400);
    }

    span:active {
      color: mat.get-color-from-palette($primary, 500);
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
