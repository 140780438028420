@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);
  $foreground: map.get($colors, foreground);

  .display-mode-text {
    .mat-form-field {
      .mat-form-field-underline {
        display: none;
      }

      .mat-select-arrow {
        visibility: hidden;
      }
    }

    .k-combobox .k-dropdown-wrap {
      border-style: none;
    }

    .visible-on-edit-mode {
      display: none !important;
    }

    &:hover {
      .visible-on-edit-mode {
        display: initial !important;
      }
    }

    .mat-form-field:hover,
    .mat-form-field.mat-focused {
      .mat-form-field-underline {
        display: block;
      }

      .mat-select-arrow {
        visibility: visible;
      }
    }

    .mat-form-field.mat-focused + .visible-on-edit-mode {
      display: block !important;
    }

    .mat-form-field-disabled {
      pointer-events: none;

      .mat-form-field-wrapper {
        .mat-input-element {
          color: mat.get-color-from-palette($foreground, text);
        }
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
