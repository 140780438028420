@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);

  .menu-group {
    .color-bar {
      background-color: mat.get-color-from-palette($background, hover);
    }
    .mat-list-item {
      background-color: mat.get-color-from-palette($background, card);
    }

    &.expanded {
      .color-bar {
        background-color: whitesmoke;
      }
      .mat-list-item {
        background-color: mat.get-color-from-palette($background, hover);
      }
    }

    &.selected {
      .color-bar {
        background-color: mat.get-color-from-palette($primary, default);
      }
      .mat-list-item {
        background-color: mat.get-color-from-palette($background, disabled-button);
      }
    }

    &:hover {
      .mat-list-item {
        background-color: mat.get-color-from-palette($background, disabled-button);
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
