@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);
  $foreground: map.get($colors, foreground);

  // firefox
  scrollbar-color: mat.get-color-from-palette($foreground, disabled) mat.get-color-from-palette($background, card);
  // chrome
  ::-webkit-scrollbar {
    width: auto;
    height: auto;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px 0 grey;
    background-color: mat.get-color-from-palette($background, card);
  }
  ::-webkit-scrollbar-thumb {
    background-color: mat.get-color-from-palette($foreground, disabled);
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
