@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);

  app-subgrid-view {
    border: 0.25em solid mat.get-color-from-palette($primary) !important;
    .subgrid-toolbar {
      background: mat.get-color-from-palette($background, grid) !important;
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
