@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);

  .datetime-filter-button {
    background-color: mat.get-color-from-palette($background, card);
    border: none;
    border-radius: 0px;
  }

  .k-calendar {
    background-color: mat.get-color-from-palette($background, card);

    .k-calendar-view {
      .k-calendar-table {
        background-color: mat.get-color-from-palette($background, card);
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
