@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);

  .list-toolbar-buttons-container {
    color: mat.get-color-from-palette($foreground, text);
  }

  .list-toolbar-element-checked {
    background-color: mat.get-color-from-palette($background, disabled-button);
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
