@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $typography: mat.get-typography-config($theme);
  $caption: mat.get-typography-config($theme);
  $colors: mat.get-color-config($theme);
  $background: map.get($colors, background);
  $foreground: map.get($colors, foreground);

  .mat-option {
    @include mat.typography-level($typography, 'input');
    height: 3em;

    &.action-option {
      height: 2em;
      @include mat.typography-level($typography, 'caption');
      font-weight: 500;
      .mat-option-text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .autocomplete-action-button {
    color: mat.get-color-from-palette($foreground, text);
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
