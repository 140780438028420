@use '@angular/material' as mat;
@use 'sass:map';

@use './scrollbars-theme.scss' as scrollbars;
@use '../shared/icon/icon-theme' as icon;
@use '../core/layout/components/busy-indicator/busy-indicator-theme.scss' as spinner;
@use '../core/menu/shared/menu-group/menu-group-theme' as menuGroup;
@use '../core/menu/shared/menu-group-items/menu-group-item-theme.scss' as menuGroupItem;
@use '../core/menu/shared/menu-popup/menu-popup-theme' as menuPopup;
@use '../core/user/user-panel/user-panel-theme' as userPanel;
@use '../shared/grid/grid-theme' as grid;
@use '../core/engine-views/components/subgrid-view/subgrid-view-theme.scss' as subgrid;
@use '../shared/reactive-controls/components/autocomplete/autocomplete-control-theme' as autocompleteControl;
@use '../core/engine-forms/components/engine-form/form-tabs/form-tabs-theme' as formTabs;
@use '../app/main-kanban/kanban/kanban-theme' as kanban;
@use '../core/notification/components/notification-bar/notification-bar-theme' as notificationBar;
@use '../shared/grid/components/aggregation-dropdown/aggregation-dropdown-theme' as aggregationDropdown;
@use '../shared/grid/components/custom-filters/datetime-filter/datetime-filter-theme' as datetimeFilter;
@use '../shared/grid/components/grid-column-cell/grid-column-cell-theme' as gridCell;
@use '../core/context-menu/context-menu/context-menu-theme.scss' as contextMenu;
@use '../shared/reactive-controls/components/_controls-theme.scss' as controls;
@use '../shared/ui-components/components/duration/duration-input-theme' as durationInput;
@use '../core/engine-views/components/engine-view/engine-view-toolbar/engine-view-toolbar-theme.scss' as viewToolbar;
@use '../shared/ui-components/components/progress-bar/_progress-bar-theme.scss' as progressBar;
@use '../shared/ui-components/components/optionset-progress-bar/_optionset-progress-bar-theme.scss' as optionSetProgressBar;
@use '../shared/reactive-controls/components/chips/_chips-control-theme' as chipsControl;
@use 'typography' as typography;
@use '../core/engine-odata/components/query-builder/_query-builder-theme.scss' as queryBuilder;
@use '../shared/reactive-controls/components/control-error/_control-error-theme' as controlError;

@import '@angular/material/theming';
@import 'color-palette.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(typography.$app-typography-config);`
@include mat.all-legacy-component-typographies(typography.$app-typography-config);
@include mat.legacy-core();

$app-primary: mat.define-palette($primary-color);
$app-accent: mat.define-palette($primary-color);
$app-warn: mat.define-palette($warn-color);
$app-error: mat.define-palette($error-color);
$app-success: mat.define-palette($success-color);

$app-light-theme: mat.define-light-theme( ( color: ( primary: $app-primary, accent: $app-accent, warn: $app-error, ), typography: typography.$app-typography-config, ) );

$app-dark-theme: mat.define-dark-theme( ( color: ( primary: $app-primary, accent: $app-accent, warn: $app-error, ), typography: typography.$app-typography-config, ) );

//Custom light-theme styles for foreground properties: secondary-text and text
$app-light-theme: map.set($app-light-theme, color, foreground, secondary-text, rgba(30, 83, 99, 0.6));
$app-light-theme: map.set($app-light-theme, color, foreground, text, rgba(30, 83, 99, 1));

//Custom grid background colors
$dark-colors: mat.get-color-config($app-dark-theme);
$dark-background: map.get($dark-colors, background);
$app-light-theme: map.set($app-light-theme, color, background, grid, #f3f3f3);
$app-dark-theme: map.set($app-dark-theme,color, background, grid, mat.get-color-from-palette($dark-background, background) );

.error-notification-overlay {
  color: mat.get-color-from-palette($app-error, default-contrast) !important;
  background-color: mat.get-color-from-palette($app-error) !important;
}

.info-notification-overlay {
  color: mat.get-color-from-palette($app-primary, default-contrast) !important;
  background-color: mat.get-color-from-palette($app-primary) !important;
}

.success-notification-overlay {
  color: mat.get-color-from-palette($app-success, default-contrast) !important;
  background-color: mat.get-color-from-palette($app-success) !important;
}

.warning-notification-overlay {
  color: mat.get-color-from-palette($app-warn, default-contrast) !important;
  background-color: mat.get-color-from-palette($app-warn) !important;
}

h1 {
  @include mat.typography-level(typography.$app-typography-config, 'headline');
}

h2 {
  @include mat.typography-level(typography.$app-typography-config, 'title');
}

h3 {
  @include mat.typography-level(typography.$app-typography-config, 'subheading-2');
}

h4 {
  @include mat.typography-level(typography.$app-typography-config, 'subheading-1');
}

body {
  @include mat.typography-level(typography.$app-typography-config, 'body-1');
  // kendo styles override svg's color so we have to reduce that
  :not(.k-svg-icon) > svg {
    fill: inherit !important;
  }
}

@mixin mix-app-theme($theme) {
  // $typography: mat.get-typography-config($theme);
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $warn: map.get($colors, warn);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);

  @include mat.all-legacy-component-colors($theme);
  @include scrollbars.theme($theme);
  @include icon.theme($theme);
  @include spinner.theme($theme);
  @include menuGroup.theme($theme);
  @include menuGroupItem.theme($theme);
  @include menuPopup.theme($theme);
  @include userPanel.theme($theme);
  @include grid.theme($theme);
  @include subgrid.theme($theme);
  @include autocompleteControl.theme($theme);
  @include formTabs.theme($theme);
  @include notificationBar.theme($theme);
  @include kanban.theme($theme);
  @include aggregationDropdown.theme($theme);
  @include datetimeFilter.theme($theme);
  @include contextMenu.theme($theme);
  @include controls.theme($theme);
  @include durationInput.theme($theme);
  @include gridCell.theme($theme);
  @include viewToolbar.theme($theme);
  @include progressBar.theme($theme);
  @include optionSetProgressBar.theme($theme);
  @include chipsControl.theme($theme);
  @include queryBuilder.theme($theme);
  @include controlError.theme($theme);
}

.light-theme {
  @include mix-app-theme($app-light-theme);
}

.dark-theme {
  @include mix-app-theme($app-dark-theme);
}
