@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $typography: mat.get-typography-config($theme);
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $background: map.get($colors, background);
  $foreground: map.get($colors, foreground);

  app-chips-control {
    .mat-form-field {
      mat-chip-grid {
        mat-chip-row {
          @include mat.typography-level($typography, 'input');
          background-color: mat.get-color-from-palette($primary, default) !important;
          color: mat.get-color-from-palette($primary, default-contrast);
        }
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
