@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $background: map.get($colors, background);

  app-form-tab {
    mat-tab-group .mat-tab-header {
      background-color: mat.get-color-from-palette($background, card);
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
