@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);

  .app-loading .spinner .path {
    stroke: mat.get-color-from-palette($primary, default) !important;
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
