$kendo-font-family: 'Nunito', 'Helvetica Neue', sans-serif;
$kendo-color-primary: #00a3d7;
@import '@progress/kendo-theme-material/scss/grid/_index.scss';
@import 'theme.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  width: 100vw;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

.pointer-cursor {
  cursor: pointer;
}

.mat-tooltip {
  font-size: small;
}

.input-wrapper {
  width: 100%;
  padding: 0 5px;

  .control-input {
    width: 100%;
  }
  .mat-input-element {
    height: 100%;
  }
}

.hiddenInput {
  display: none !important;
}

//bad things
.mat-tab-label.mat-ripple.mat-tab-disabled {
  display: none;
}

.app-expander-dialog .mat-dialog-container {
  padding-top: 10px;
}

.mat-tab-body-wrapper {
  flex: 1;
}

input.mat-input-element {
  font-weight: 600 !important;
}

.mat-form-field .mat-form-field-label {
  font-weight: 600 !important;
}

.mat-simple-snack-bar-content {
  font-weight: 700 !important;
}
