@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);

  app-user-panel {
    .mat-toolbar {
      background-color: mat.get-color-from-palette($background, card);
    }
    .display-flex {
      .user-email {
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
