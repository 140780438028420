@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $foreground: map.get($colors, foreground);
  $primary: map.get($colors, primary);

  // TODO-MP: remove this file when all mat depraceted components replaced with new ones
  // replace @include mat.all-legacy-component-colors($theme); with @include mat.all-component-themes($theme); in theme.scss

  app-query-builder {
    .q-button-group button mat-icon {
      color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    .mat-mdc-radio-button
      .mdc-radio:not(:disabled):active
      .mdc-radio__native-control:enabled:not(:checked)
      + .mdc-radio__background
      .mdc-radio__outer-circle,
    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:not(:checked)
      + .mdc-radio__background
      .mdc-radio__outer-circle {
      border-color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle,
    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled
      + .mdc-radio__background
      .mdc-radio__inner-circle,
    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:checked:focus
      + .mdc-radio__background
      .mdc-radio__outer-circle,
    .mat-mdc-radio-button
      .mdc-radio
      .mdc-radio__native-control:enabled:focus
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border-color: mat.get-color-from-palette($primary, default);
    }

    .mat-mdc-radio-button.mat-mdc-radio-checked .mat-radio-ripple .mat-ripple-element {
      background-color: mat.get-color-from-palette($primary, default);
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
