@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $primary: map.get($colors, primary);
  $foreground: map.get($colors, foreground);
  $background: map.get($colors, background);

  kendo-popup {
    border-radius: 10px !important;

    .k-popup {
      border-radius: 10px !important;

      kendo-grid-filter-menu-container,
      .k-grid-filter-popup,
      .k-filter-menu,
      .k-filter-menu-container {
        border-radius: 10px !important;
      }
    }
  }

  app-grid table.k-grid-table.k-table {
    border-collapse: separate !important;
    border-spacing: 0 0.5em !important;
    background-color: transparent !important;

    tr {
      background-color: transparent !important;
    }
  }

  app-grid {
    .k-grid,
    .k-grid-header,
    .k-grid-content,
    .k-grid-pager {
      border: none;
      background-color: mat.get-color-from-palette($background, grid);
      color: mat.get-color-from-palette($foreground, text);

      .k-link,
      .k-link:hover,
      .k-icon-wrapper-host {
        color: mat.get-color-from-palette($foreground, text);
      }

      .k-icon-wrapper-host:hover {
        color: mat.get-color-from-palette($primary);
      }
    }

    .k-grid {
      .k-grid-header {
        .k-grid-header-wrap,
        .k-grid-header-locked {
          table {
            tr,
            tr.k-selected,
            tr.k-selected:hover,
            tr.k-selected.k-hover {
              border: none;
              background-color: mat.get-color-from-palette($background, grid);

              td.k-table-td,
              th.k-table-th {
                border: none;
                background-color: mat.get-color-from-palette($background, grid);

                .k-column-resizer {
                  border-right: 1px dotted mat.get-color-from-palette($primary, 400);

                  &:hover {
                    border: none;
                  }
                }

                .k-grid-filter.k-active {
                  background-color: mat.get-color-from-palette($primary, 400);

                  .k-icon-wrapper-host:hover {
                    color: mat.get-color-from-palette($background, grid);
                  }
                }
              }
            }
          }
        }

        .k-grid-header-wrap table tr {
          td.k-table-td:last-child,
          th.k-table-th:last-child {
            border-right-width: 1px;
          }
        }
      }

      .k-grid-content-locked,
      .k-grid-content {
        .k-grid-table-wrap table {
          tr {
            border: none;
            background-color: mat.get-color-from-palette($background, grid);

            td.k-table-td {
              border: none;
              background-color: mat.get-color-from-palette($background, card);
              color: mat.get-color-from-palette($foreground, text);
            }
          }
        }
      }
    }
  }

  .k-popup.k-grid-filter-popup .k-filter-menu-container {
    color: mat.get-color-from-palette($background, text);
    background-color: mat.get-color-from-palette($background, card);

    .k-dropdown-wrap,
    .k-numeric-wrap,
    .k-dateinput-wrap,
    .k-picker-wrap,
    .k-dropdowntree,
    .k-textbox,
    .k-numerictextbox,
    .k-input,
    .k-select,
    .k-input-inner {
      border: none;
      color: mat.get-color-from-palette($background, text);
    }

    .k-button.k-primary:not(:disabled) {
      color: mat.get-color-from-palette($primary, default-contrast);
      background-color: mat.get-color-from-palette($primary);
    }
  }

  .k-popup .k-list .k-list-item {
    border: none;
    color: mat.get-color-from-palette($foreground, text);
    background-color: mat.get-color-from-palette($background, card);

    &:hover,
    .k-hover,
    .k-hovered {
      background-color: mat.get-color-from-palette($background, hover);
      color: mat.get-color-from-palette($primary);
    }

    &.k-selected {
      color: mat.get-color-from-palette($primary);
    }
  }
  // hacks to hide action buttons on combined texts column filters
  .k-filter-menu-container {
    app-combined-texts-filter ~ .k-actions {
      display: none;
    }
  }

  .kendo-pager {
    color: mat.get-color-from-palette($foreground, text);
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
