@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $foreground: map.get($colors, foreground);

  .aggregation-container {
    .mat-select-disabled .mat-select-value,
    .mat-select-value {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
