@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $background: map.get($colors, background);
  $foreground: map.get($colors, foreground);
  $primary: map.get($colors, primary);

  app-kanban-toolbar {
    mat-toolbar {
      background: mat.get-color-from-palette($background, card);
      border-color: mat.get-color-from-palette($primary, default);
    }
  }

  app-kanban-column {
    background: mat.get-color-from-palette($background, card);
  }

  app-kanban-column-tile {
    background: mat.get-color-from-palette($background, hover);
  }

  app-kanban-column-tile:not(:hover) {
    border-top-color: mat.get-color-from-palette($background, hover) !important;
    border-bottom-color: mat.get-color-from-palette($background, hover) !important;
    border-right-color: mat.get-color-from-palette($background, hover) !important;
  }

  .kanban-control-value {
    color: mat.get-color-from-palette($foreground, text) !important;
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
