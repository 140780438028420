@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $background: map.get($colors, background);

  app-notification-bar {
    .mat-expansion-panel-header.mat-expanded {
      background-color: mat.get-color-from-palette($background, card);

      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }

      &:focus {
        background-color: mat.get-color-from-palette($background, disabled-button);
      }
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);
  @if $colors != null {
    @include color($theme);
  }
}
