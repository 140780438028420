@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $colors: mat.get-color-config($theme);
  $foreground: map.get($colors, foreground);
  $primary: map.get($colors, primary);
  $accent: map.get($colors, accent);
  $warn: map.get($colors, warn);

  progress[value] {
    &.primary {
      accent-color: mat.get-color-from-palette($primary, 600);
    }

    &.accent {
      accent-color: mat.get-color-from-palette($accent);
    }

    &.warn {
      accent-color: mat.get-color-from-palette($warn);
    }
  }
}

@mixin theme($theme) {
  $colors: mat.get-color-config($theme);

  @if $colors != null {
    @include color($theme);
  }
}
